import Turbolinks from 'turbolinks';
Turbolinks.start();

import 'bootstrap/js/dist/carousel';
import * as Carousel from 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tab';
import PhotoSwipe from '../../vendor/photoswipe/photoswipe.js';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';
import LazyLoad from 'vanilla-lazyload';

import 'stylesheets/spree/frontend';

window['dataLayer'] = window['dataLayer'] || [];
window['gtag'] = function () {
  window['dataLayer'].push(arguments);
};
window['gtag']('js', new Date());

document.addEventListener('DOMContentLoaded', (event) => {
  new LazyLoad({
    elements_selector: `.lazy`,
    use_native: true,
  });

  if (window['CLARITY_ID']) {
    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = 'https://www.clarity.ms/tag/' + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, 'clarity', 'script', window['CLARITY_ID']);
  }

  if (window['LINKEDIN_ID']) {
    const _linkedin_partner_id = window['LINKEDIN_ID'];
    window['_linkedin_data_partner_ids'] =
      window['_linkedin_data_partner_ids'] || [];
    window['_linkedin_data_partner_ids'].push(_linkedin_partner_id);

    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
    document.querySelector('head').appendChild(script);
  }

  if (window['LEAD_FORENSICS_ID']) {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://secure.bass2poll.com/js/${window['LEAD_FORENSICS_ID']}.js`;
    document.querySelector('head').appendChild(script);
  }
});

document.addEventListener('turbolinks:load', (event) => {
  if (window['GOOGLE_ANALYTICS_ID']) {
    window['gtag']('config', window['GOOGLE_ANALYTICS_ID'], {
      page_location: (event as any).data.url,
    });
  }

  if (window['HUBSPOT_ID']) {
    const hsScript = document.querySelector('#hs-script-loader');
    if (hsScript) {
      hsScript.remove();
      window['hubspot_live_messages_running'] = undefined;
      window['HubSpotConversations'] = undefined;
    }

    const hsScriptLoaders = document.querySelectorAll(
      'script[data-loader="hs-scriptloader"]'
    );
    hsScriptLoaders.forEach((script) => script.remove());

    const hsAnalytics = document.getElementById('hs-analytics');
    if (hsAnalytics) {
      hsAnalytics.remove();
    }

    const script = document.createElement('script');
    script.id = 'hs-script-loader';
    script.async = true;
    script.defer = true;
    script.src = `https://js.hs-scripts.com/${window['HUBSPOT_ID']}.js`;
    document.querySelector('head').appendChild(script);
  }

  const carouselHeroes: HTMLDivElement =
    document.querySelector('#carousel-heroes');
  if (carouselHeroes) {
    const carousel = new Carousel.default(carouselHeroes, {
      interval: 8000,
    });
    carousel.cycle();
  }

  new LazyLoad({
    elements_selector: `.lazy`,
    use_native: true,
  });

  if (!('ontouchstart' in document.documentElement)) {
    const scrollingWrapper: HTMLDivElement =
      document.querySelector('.scrolling-wrapper');
    if (scrollingWrapper) {
      let isDown = false;
      let startX: number;
      let scrollLeft: number;

      scrollingWrapper.addEventListener('mousedown', (e: MouseEvent) => {
        isDown = true;
        scrollingWrapper.classList.add('active');
        startX = e.pageX - scrollingWrapper.offsetLeft;
        scrollLeft = scrollingWrapper.scrollLeft;
      });

      scrollingWrapper.addEventListener('mouseleave', () => {
        isDown = false;
        scrollingWrapper.classList.remove('active');
      });

      scrollingWrapper.addEventListener('mouseup', () => {
        isDown = false;
        scrollingWrapper.classList.remove('active');
      });

      scrollingWrapper.addEventListener('mousemove', (e) => {
        if (!isDown) {
          return;
        }

        e.preventDefault();
        const x = e.pageX - scrollingWrapper.offsetLeft;
        const walk = (x - startX) * 1; //scroll-fast
        scrollingWrapper.scrollLeft = scrollLeft - walk;
      });
    }
  }
});

const onGalleryThumbnailClick = (
  index: number,
  element: HTMLAnchorElement,
  e: Event,
  force = false
) => {
  e.preventDefault();

  if (force || !element.classList.contains('border-primary')) {
    const galleryThumbnails = document.querySelectorAll('.gallery-thumbnail');
    if (galleryThumbnails.length > 0) {
      galleryThumbnails.forEach(function (thumbnail) {
        thumbnail.classList.remove('border-primary');
      });
      element.classList.add('border-primary');

      const galleryImage: HTMLAnchorElement =
        document.querySelector('.gallery-image');
      if (galleryImage) {
        galleryImage.href = element.href;
        galleryImage.dataset.index = `${index}`;
        (galleryImage.children[0] as HTMLImageElement).src = element.href;
      }
    }
  }
};
window['onGalleryThumbnailClick'] = onGalleryThumbnailClick;

const onGalleryImageClick = (element: HTMLAnchorElement, event: Event) => {
  event.preventDefault();

  const pswpElement = document.querySelectorAll('.pswp')[0];
  if (pswpElement) {
    const options = {
      index: parseInt(element.dataset.index),
      // history: false
    };

    const gallery = new PhotoSwipe(
      pswpElement,
      PhotoSwipeUI_Default,
      window['pswpItems'],
      options
    );
    gallery.init();
  }
};
window['onGalleryImageClick'] = onGalleryImageClick;

const onProductVariantSelect = (element: HTMLSelectElement) => {
  const images = window['variantsImages'][element.selectedIndex];
  window['pswpItems'] = images;

  const variantGalleries: NodeListOf<HTMLDivElement> =
    document.querySelectorAll('.gallery-variant');
  variantGalleries.forEach((variantGallery) => {
    variantGallery.classList.remove('d-flex');
    variantGallery.classList.add('d-none');
  });

  const selectedVariantGallery: HTMLDivElement = document.querySelector(
    `.gallery-variant-${element.selectedIndex}`
  );
  if (selectedVariantGallery) {
    selectedVariantGallery.classList.remove('d-none');
    selectedVariantGallery.classList.add('d-flex');

    onGalleryThumbnailClick(
      0,
      selectedVariantGallery.children[0].children[0] as HTMLAnchorElement,
      new Event('click'),
      true
    );
  }

  const variantPropertiesList: NodeListOf<HTMLDivElement> =
    document.querySelectorAll('.properties-variant');
  variantPropertiesList.forEach((variantProperties) => {
    variantProperties.classList.remove('d-block');
    variantProperties.classList.add('d-none');
  });

  const selectedVariantProperties: HTMLDivElement = document.querySelector(
    `.properties-variant-${element.selectedIndex}`
  );
  if (selectedVariantProperties) {
    selectedVariantProperties.classList.remove('d-none');
    selectedVariantProperties.classList.add('d-block');
  }

  const variantDimensionsList: NodeListOf<HTMLDivElement> =
    document.querySelectorAll('.dimensions-variant');
  variantDimensionsList.forEach((variantDimensions) => {
    variantDimensions.classList.remove('d-block');
    variantDimensions.classList.add('d-none');
  });

  const selectedVariantDimensions: HTMLDivElement = document.querySelector(
    `.dimensions-variant-${element.selectedIndex}`
  );
  if (selectedVariantDimensions) {
    selectedVariantDimensions.classList.remove('d-none');
    selectedVariantDimensions.classList.add('d-block');
  }
};
window['onProductVariantSelect'] = onProductVariantSelect;
